import { Avatar, Button, Entity, Modal, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useAuth, useMustBeAuthenticated } from '../../../state/auth';
import { AnimateScenes } from '../../content/animateScenes';

let Wrapper = styled('div')`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;

  img {
    width: 30px;
    height: 30px;
  }
`;

export let User = () => {
  let { user, logout } = useAuth();
  useMustBeAuthenticated();

  let [userModalOpen, setUserModalOpen] = useState(false);
  let [scene, setScene] = useState<'overview' | 'logout'>('overview');

  useEffect(() => {
    if (userModalOpen) setScene('overview');
  }, [userModalOpen]);

  return (
    <>
      <Wrapper
        role="button"
        onClick={() => setUserModalOpen(true)}
        onKeyDown={e => e.key == 'Enter' && setUserModalOpen(true)}
        tabIndex={0}
      >
        {user && <img src={user.imageUrl} alt={user.name} />}
      </Wrapper>

      {user && (
        <Modal.Wrapper
          isOpen={userModalOpen}
          onClose={() => setUserModalOpen(false)}
          label="Account Quick Actions"
          width={350}
        >
          <Modal.Header>{user.name}</Modal.Header>
          <Modal.Content>
            <AnimateScenes currentKey={scene}>
              {scene == 'overview' && (
                <>
                  <Entity.Wrapper>
                    <Entity.Content>
                      <Entity.Field
                        title={user.name}
                        description={user.primaryEmail}
                        prefix={<Avatar size={30} src={user.imageUrl} name={user.name} />}
                      />
                    </Entity.Content>
                  </Entity.Wrapper>

                  <Spacer height={15} />

                  <Link href="/account">
                    <Button
                      as="div"
                      variant="tertiary"
                      onClick={() => setUserModalOpen(false)}
                    >
                      Your Account
                    </Button>
                  </Link>

                  <Spacer height={15} />

                  <Link href="/teams">
                    <Button
                      as="div"
                      variant="tertiary"
                      onClick={() => setUserModalOpen(false)}
                    >
                      Your Teams
                    </Button>
                  </Link>

                  <Spacer height={15} />

                  <Button fullWidth variant="secondary" onClick={() => setScene('logout')}>
                    Logout
                  </Button>
                </>
              )}

              {scene == 'logout' && (
                <>
                  <Text size={18} weight={500} style={{ textAlign: 'center' }}>
                    Do you want to log out?
                  </Text>

                  <Spacer height={20} />

                  <Button variant="primary" onClick={() => logout()} fullWidth>
                    Logout
                  </Button>

                  <Spacer height={10} />

                  <Button variant="tertiary" onClick={() => setScene('overview')} fullWidth>
                    Go Back
                  </Button>
                </>
              )}
            </AnimateScenes>
          </Modal.Content>
        </Modal.Wrapper>
      )}
    </>
  );
};
