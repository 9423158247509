import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import useDelayed from 'use-delayed';

export let AnimateScenes = ({
  children,
  currentKey
}: {
  children: React.ReactNode;
  currentKey: string;
}) => {
  let [measureRef, { height }] = useMeasure();
  let delayedKey = useDelayed(currentKey, 250);
  let [initialKey, setInitialKey] = useState<string | undefined>(() => currentKey);
  let delayedHeight = useDelayed(height, 200);

  useEffect(() => {
    if (initialKey && initialKey != currentKey) {
      setInitialKey(undefined);
    }
  }, [currentKey]);

  return (
    <motion.div
      animate={{ height: delayedKey == currentKey ? height : delayedHeight || height }}
      transition={{ duration: 0.15, ease: 'linear' }}
      style={{ overflow: 'hidden' }}
    >
      <AnimatePresence>
        <motion.div
          key={currentKey}
          initial={initialKey == currentKey ? { opacity: 1, x: 0 } : { opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -0 }}
          transition={
            delayedKey == currentKey
              ? { duration: 0.2, delay: 0 }
              : { duration: 0.2, delay: 0.25 }
          }
        >
          <div>
            <div
              ref={e => {
                measureRef(e as any);
              }}
            >
              {children}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};
