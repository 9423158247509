import { Avatar, Menu } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronDown, ChevronRight } from 'react-feather';
import { useWindowSize } from 'react-use';
import { useAuth } from '../../../state/auth';
import { trpc } from '../../../state/trpc';
import { Logo } from '../logo';
import { User } from './user';

let Wrapper = styled('header')`
  border-bottom: 1px solid #dddddd;
  background: #f6f6f6;
  position: sticky;
  top: 0;
  z-index: 1010;
`;

let Inner = styled('nav')`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 10px;
  display: flex;
`;

let LogoWrapper = styled('div')`
  display: flex;
  gap: 20px;
  color: #000000;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

let Left = styled('div')`
  display: flex;
  gap: 20px;
  flex-grow: 1;
`;

let Right = styled('div')`
  display: flex;
  gap: 20px;
`;

let TeamButton = styled('button')`
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  border: none;
  background: none;
  padding: 0;

  svg {
    height: 16px;
    width: 16px;
    margin-top: 7px;
  }

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
`;

let Separator = styled('div')`
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    opacity: 0.35;
  }
`;

export let GlobalNav = () => {
  let router = useRouter();
  let { teams } = useAuth();
  let teamId = router.query.teamId as string;
  let projectId = router.query.projectId as string;
  let { width } = useWindowSize();
  let isSmallScreen = typeof window != 'undefined' && width < 700;

  let currentTeam = teams?.find(team => team.id == teamId || team.slug == teamId);
  let hasTeams = teams && teams?.length > 0;

  let { data: projectData } = trpc.project.list.useQuery(
    { teamId: teamId! },
    { enabled: !!teamId }
  );
  let currentProject = projectData?.projects?.find(project => project.id == projectId);

  return (
    <Wrapper>
      <Inner>
        <Left>
          <Link href={hasTeams ? '/teams' : '/teams/create'}>
            <LogoWrapper>
              <Logo />
              <p>SparkAuth</p>
            </LogoWrapper>
          </Link>

          <Separator>
            <ChevronRight />
          </Separator>

          {(!teamId || !isSmallScreen) && (
            <Menu
              minWidth={140}
              label="Teams"
              action={id => {
                if (id == 'create-team') {
                  router.push('/teams/create');
                } else {
                  router.push(`/team/${id}`);
                }
              }}
              items={[
                ...((teams || []).map(team => ({
                  id: team.slug,
                  type: 'item',
                  component: (
                    <Menu.Entity
                      title={team.name}
                      description={team.slug}
                      icon={<Avatar name={team.name} src={team.imageUrl} size={30} />}
                    />
                  )
                })) as any),
                hasTeams
                  ? {
                      id: 'sep1',
                      type: 'separator'
                    }
                  : null,
                {
                  id: 'create-team',
                  type: 'item',
                  component: 'Create Team'
                }
              ].filter(Boolean)}
            >
              {({ attrs, ref }) => (
                <TeamButton {...attrs} ref={ref}>
                  {currentTeam ? (
                    <>
                      <img src={currentTeam.imageUrl} alt={currentTeam.name} />
                      <p>{currentTeam.name}</p>
                    </>
                  ) : (
                    <p>Select Team</p>
                  )}

                  <ChevronDown />
                </TeamButton>
              )}
            </Menu>
          )}

          {teamId && projectData?.projects?.length != 0 && (
            <>
              {!isSmallScreen && (
                <Separator>
                  <ChevronRight />
                </Separator>
              )}

              <Menu
                minWidth={140}
                label="Projects"
                action={id => {
                  router.push(id);
                }}
                items={
                  (projectData?.projects || []).map(project => ({
                    id: `/team/${teamId}/project/${project.id}/instance/${
                      project.instances.find(i => i.isDev)?.id
                    }`,
                    type: 'item',
                    component: project.name
                  })) as any
                }
              >
                {({ attrs, ref }) => (
                  <TeamButton {...attrs} ref={ref}>
                    {currentProject ? (
                      <>
                        <p>{currentProject.name}</p>
                      </>
                    ) : (
                      <p>Select Project</p>
                    )}

                    <ChevronDown />
                  </TeamButton>
                )}
              </Menu>
            </>
          )}
        </Left>

        <Right>
          <User />
        </Right>
      </Inner>
    </Wrapper>
  );
};
